<template>
  <router-view/>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
  },
  components: {}
})
</script>

<style>
html,body{
  background-color: #F2F2F2;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 !important; /*防止el-popup-parent--hidden弹框bug问题*/
  padding: 0 !important; /*防止el-popup-parent--hidden弹框bug问题*/
}
.out{
  width:100%;
  padding: 10px 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 2px 5px #D1D1D1;
}
.el-popup-parent--hidden { 
  overflow: initial !important; /*防止el-popup-parent--hidden弹框bug问题*/
}
/* 输入框样式 */
.el-input__inner::placeholder {
  color: #a8abb2 !important;
}
.el-input__inner{
  color: #333 !important;
}
</style>